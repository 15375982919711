import React from "react"
import App from "./src/components/app"
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";

export const wrapRootElement = ({ element }) => {
    // console.log('Gatsby Browser: wrapRootElement called...');
 return (
    <App>
        {element}
    </App>
 )
}