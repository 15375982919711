import React, { useState, useEffect, useReducer } from "react";
import { navigate } from "gatsby"
import { isBrowser } from "./utils/utils"

export const AppContext = React.createContext(); // Global App context
// TODO: cleanup
// initial app state
const initialState = {
  products: [
    {
      name: "dojolvi",
      label: "Dojolvi",
    },
    {
      name: "mepsevii",
      label: "Mepsevii",
    },
  ],
  userForm: {
    product: "",
    activeStepIndex : 0,
    formData: {
      files: [],
    },
  },
  pdfPages: {
    files: [],
    stepFive: ""
  }
}

// Reducer function handles dispatched actions in the app
const reducer = (state, action) => {
    // console.log('%c App Context: Reducer: Action: ', 'background-color: #8feb34; font-size: 12px', action);
    switch (action.type) {

        // Add User
        case "ADD_USER":
            localStorage.setItem("user", JSON.stringify(action.payload));
        return {
            ...state,
            user: action.payload,
        };

        // Choose Product
        case "UPDATE_PRODUCT":
            localStorage.setItem("product", JSON.stringify(action.payload));
        return {
            ...state,
            userForm: {
                ...state.userForm,
                product: action.payload
            }
        };

        // Choose Product
        case "UPDATE_STEP":
            localStorage.setItem("step", JSON.stringify(action.payload));
        return {
            ...state,
            userForm: {
                ...state.userForm,
                activeStepIndex: action.payload
            }
        };

        // Update Form Data
        case "UPDATE_FORM_DATA":
            localStorage.setItem("formData", JSON.stringify(action.payload));
        return {
            ...state,
            userForm: {
                ...state.userForm,
                formData: action.payload
            }
        };

        // Update pdf images
        case "UPDATE_PDF_IMAGE_STEP_ZERO":
            // localStorage.setItem("pdfPagesStepZero", JSON.stringify(action.payload));
        return {
            ...state,
            pdfPages: {
                ...state.pdfPages,
                stepZero: action.payload
            }
        }
        case "UPDATE_PDF_IMAGE_STEP_ONE":
            // localStorage.setItem("pdfPagesStepOne", JSON.stringify(action.payload));
        return {
            ...state,
            pdfPages: {
                ...state.pdfPages,
                stepOne: action.payload
            }
        }
        case "UPDATE_PDF_IMAGE_STEP_TWO":
            // localStorage.setItem("pdfPagesStepTwo", JSON.stringify(action.payload));
        return {
            ...state,
            pdfPages: {
                ...state.pdfPages,
                stepTwo: action.payload
            }
        }
        case "UPDATE_PDF_UPLOADED_IMAGE":
        return {
            ...state,
            pdfPages: {
                ...state.pdfPages,
                files: action.payload
            }
        }
        case "UPDATE_PDF_IMAGE_STEP_THREE":
            // localStorage.setItem("pdfPagesStepThree", JSON.stringify(action.payload));
        return {
            ...state,
            pdfPages: {
                ...state.pdfPages,
                stepThree: action.payload
            }
        }
        case "UPDATE_PDF_IMAGE_STEP_FOUR":
            // localStorage.setItem("pdfPagesStepFour", JSON.stringify(action.payload));
        return {
            ...state,
            pdfPages: {
                ...state.pdfPages,
                stepFour: action.payload
            }
        }
        case "UPDATE_PDF_IMAGE_STEP_FIVE":
            // localStorage.setItem("pdfPagesStepFive", JSON.stringify(action.payload));
        return {
            ...state,
            pdfPages: {
                ...state.pdfPages,
                stepFive: action.payload
            }
        }

        // Update Form Data
        case "UPDATE_FILE_UPLOADS":
            localStorage.setItem("fileUploads", JSON.stringify(action.payload));
        return {
            ...state,
            userForm: {
                ...state.userForm,
                formData: {
                  ...state.userForm.formData,
                  files: action.payload
                }
            }
        };

        case "UPDATE_FILES_UPLOADED":
            localStorage.setItem("filesUploaded", action.payload)
        return {
            ...state,
            userForm: {
                ...state.userForm,
                formData: {
                    ...state.userForm.formData,
                    files_uploaded: action.payload
                }
            }
        }

        // Reset
        case "RESET":
            // console.log('RESET: clearing localStorage...');
            // console.log('RESET: reset to initialState...');
            localStorage.clear();
        return {
            ...initialState,
        };

        // DEFAULT action
        default:
            return state;
    }
};

const App = ({ children }) => {
  // console.log("App component...")

  const resetApp = () => {
    // console.log("resetApp called...")
    dispatch({
      type: "RESET",
    })
  }

  const resetDefaults = () => {
    // console.log("resetDefaults called...")
    if (isBrowser()) {
      // console.log("resetDefaults: clearing localStorage...")
      localStorage.clear()
      // console.log("resetDefaults:  reset to default state localStorage...")
      localStorage.setItem("appState", JSON.stringify(initialState))
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    // console.log("useEffect: App: mount/load...")
    // resetApp();

    // reset state to initial state in localStorage when component/page unmounts
    const onbeforeunloadFn = (event) => {
      // console.log("onbeforeunloadFn called: event: ", event)
      resetDefaults()
      // localStorage.setItem('appState', JSON.stringify(state) );
    }
    window.addEventListener("beforeunload", onbeforeunloadFn)
    return () => {
      // console.log("useEffect cleanup: removeEventListener onbeforeunloadFn")
      window.removeEventListener("beforeunload", onbeforeunloadFn)
    }
  }, []) // Only on initial load.

  useEffect(() => {
    // console.log("APP COMPONENT: state updated: state", state);

    // console.log("localStorage update appState...");
    localStorage.setItem("appState", JSON.stringify(state));

    // cancel subscription to useEffect
    return () => {
      // console.log('useEffect cleanup: state: cleanup...')
    }
  }, [state]) // Only re-run the effect if state changes

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
        resetApp,
      }}
    >
      <React.StrictMode>
        <div className="App">{children}</div>
      </React.StrictMode>
    </AppContext.Provider>
  )
}

export default App;
