import React from "react";
import { AppContext } from "../app";

// Check if window is defined (in browser or in node.js).
export const isBrowser = () => typeof window !== "undefined";

export const showTabs = () => {
  if ( isBrowser() ) {
    return (
      window.location.pathname != "/crysvita/" && window.location.pathname!= "/dojolvi/" && window.location.pathname!= "/mepsevii/"
    );
  }
}

export const StateUtils = () => {
  // shared context
  const { state, dispatch } = React.useContext(AppContext);

  return (
    <div style={{ color: "#fff" }}>
      <div>App: {state.user}</div>
    </div>
  );
};